import { render, staticRenderFns } from "./developmentplanning.vue?vue&type=template&id=b406d6f8"
import script from "./developmentplanning.vue?vue&type=script&setup=true&lang=js"
export * from "./developmentplanning.vue?vue&type=script&setup=true&lang=js"
import style0 from "./developmentplanning.vue?vue&type=style&index=0&id=b406d6f8&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports