var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{},[_vm._m(0),_c('div',{staticClass:"menu-icon"},[_c('ul',[_vm._m(1),_c('li',{staticClass:"goToTop"},[_c('a',{attrs:{"title":"校长信箱","target":"_blank"},on:{"click":function($event){return _vm.goUrl()}}},[_c('i',{staticClass:"fa fa-envelope",attrs:{"aria-hidden":"true"}}),_vm._v("校长信箱 ")])]),_c('li',{staticClass:"goToTop",on:{"click":_vm.goTop}},[_vm._m(2)])])]),_c('div',{staticClass:"bg-bottgray position-fixed bott-fixed phoblack"},[_c('img',{staticClass:"gzhImg1",attrs:{"src":require("../assets/gzh.jpg"),"id":"gzhImg1"}}),_c('div',{staticClass:"kaoright",on:{"click":_vm.dianjiImg}},[_vm._m(3)]),_c('ul',{staticClass:"text-center backsame"},[_c('li',{staticClass:"col-xs-4",on:{"click":function($event){return _vm.back()}}},[_vm._m(4)]),_c('li',{staticClass:"col-xs-4"},[_c('a',{on:{"click":function($event){return _vm.goUrl()}}},[_c('i',{staticClass:"fa fa-envelope"}),_c('span',{staticClass:"text-white font-size-12"},[_vm._v("校长信箱")])])]),_c('li',{staticClass:"col-xs-4"},[_c('div',{staticClass:"curpointer goToTop",on:{"click":_vm.goTop}},[_vm._m(5)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('footer',[_c('div',{staticClass:"container addfoot"},[_c('div',{staticClass:"line-height-2 text-white font-size-16"},[_vm._v(" 版权所有 © 2024 华容县职业中专 "),_c('p',[_vm._v("招生热线："),_c('img',{staticStyle:{"width":"16px","height":"16px","margin-bottom":"2px","margin-right":"3px"},attrs:{"src":require("../assets/lxr.png")}}),_vm._v("胡老师 13974063340 "),_c('img',{staticStyle:{"width":"16px","height":"16px","margin-bottom":"2px","margin-right":"3px"},attrs:{"src":require("../assets/lxr.png")}}),_vm._v("张老师 13762078505")]),_c('p',[_vm._v("联系地址：湖南省华容县章华镇黎淳东路03号")]),_c('p',[_vm._v("ICP备案："),_c('a',{staticStyle:{"color":"#fff"},attrs:{"href":"https://beian.miit.gov.cn/","target":"_blank"}},[_vm._v("湘ICP备2024044396号")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"goToTop gzh"},[_c('img',{staticClass:"gzhImg",attrs:{"src":require("../assets/gzh.jpg")}}),_c('a',{attrs:{"title":"公众号","target":"_blank"}},[_c('i',{staticClass:"fa fa-wechat",attrs:{"aria-hidden":"true"}}),_vm._v("公众号 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"title":"返回顶部","target":"_blank"}},[_c('i',{staticClass:"fa fa-arrow-up",attrs:{"aria-hidden":"true"}}),_vm._v("返回顶部 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"title":"公众号","target":"_blank"}},[_c('i',{staticClass:"fa fa-wechat",attrs:{"aria-hidden":"true"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',[_c('i',{staticClass:"fa fa-home"}),_c('span',[_vm._v("首页")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',[_c('i',{staticClass:"fa fa-caret-square-o-up"}),_c('span',[_vm._v("置顶")])])
}]

export { render, staticRenderFns }