import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import $ from 'jquery'
import Meta from 'vue-meta'
Vue.use(Meta)

Vue.prototype.$httpUrls = 'https://www.hrxzyzz.com/api/';
// Vue.prototype.$httpUrls = 'https://192.168.0.115/api/';
Vue.use(ElementUI);
Vue.config.productionTip = false
router.afterEach((to, from, next) => {
	window.scrollTo(0, 0);
	// chrome 
	document.body.scrollTop = 0
	// firefox
	document.documentElement.scrollTop = 0
	// safari window.pageYOffset = 0 
});
new Vue({
	router: router,
	store,
	$,
	render: h => h(App)
}).$mount('#app')