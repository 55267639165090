import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index.vue'
import list from '../views/list.vue'
import schoolprofile from '../views/schoolprofile.vue'
import partybuilding from '../views/partybuilding.vue'
import moraleducation from '../views/moraleducation.vue'
import teachingcorner from '../views/teachingcorner.vue'
import recruitment from '../views/recruitment.vue'
import practicaltraining from '../views/practicaltraining.vue'
import loading from '../views/loading.vue'
import developmentplanning from '../views/developmentplanning.vue'
import socialservices from '../views/socialservices.vue'
import information from '../views/information.vue'
import details from '../views/details.vue'
import mailbox from '../views/mailbox.vue'
import clubactivities from '../views/clubactivities.vue'

Vue.use(VueRouter)

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}
const routes = [{
		path: '/',
		name: '华容县职业中专',
		component: index,
	}, {
		path: '/index',
		name: '华容县职业中专',
		component: index
	},
	{
		path: '/list',
		name: '列表',
		component: list
	},
	{
		path: '/details',
		name: '详情',
		component: details
	},
	{
		path: '/schoolprofile',
		name: '学校概况',
		component: schoolprofile
	},
	{
		path: '/partybuilding',
		name: '党建纪检',
		component: partybuilding
	},
	{
		path: '/moraleducation',
		name: '德育园地',
		component: moraleducation
	},
	{
		path: '/teachingcorner',
		name: '教学园地',
		component: teachingcorner
	},
	{
		path: '/recruitment',
		name: '招生就业',
		component: recruitment
	},
	{
		path: '/practicaltraining',
		name: '教研实训',
		component: practicaltraining
	},
	{
		path: '/loading',
		name: '加载中...',
		component: loading
	},
	{
		path: '/developmentplanning',
		name: '发展规划',
		component: developmentplanning
	},
	{
		path: '/socialservices',
		name: '社会服务',
		component: socialservices
	},
	{
		path: '/information',
		name: '新闻资讯',
		component: information
	},
	{
		path: '/mailbox',
		name: '校长信箱',
		component: mailbox
	},
	{
		path: '/clubactivities',
		name: '社团活动',
		component: clubactivities
	},
	// {
	// 	path: '/about',
	// 	name: 'about',
	// 	// route level code-splitting
	// 	// this generates a separate chunk (about.[hash].js) for this route
	// 	// which is lazy-loaded when the route is visited.
	// 	component: () => import( /* webpackChunkName: "about" */ '../views/AboutView.vue')
	// }
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	// scrollBehavior(to, from, savedPosition) {
	// 	if (savedPosition) {
	// 		return savedPosition
	// 	} else {
	// 		return {
	// 			x: 0,
	// 			y: 0
	// 		}
	// 	}
	// }
})

export default router