<template>
	<main v-cloak>
		<HEADER navIndex="1"></HEADER>
		<div class="pagebanner position-relative">
			<img src="../assets/img/banner0101.jpg" />
		</div>
		<div class="container">
			<div class="row">
				<div class="col-md-3 col-sm-4 col-xs-12">
					<div class="sub-nav-box">
						<div class="sub-nav-title clearfix">
							{{type}}
						</div>
						<div class="sub-nav" id="subNav">
							<ul>
								<li v-for="(item,index) in hrxzzlist" :key="index" @click="getList(index)">
									<a :class="index==num?'color2':'color1'">{{item.article_type_sub}}</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="col-md-9 col-sm-8 col-xs-12">
					<div class="all-Breadcrumb">
						<div class="float-left visible-md hidden-sm hidden-xs" v-for="(item,index) in hrxzzlist"
							:key="index" v-if="index==num">{{item.article_type_sub}}</div>
						<div class="float-right">
							<ul class="breadcrumb">
								<li @click="back()"><a><i class="fa fa-home"></i><span>
											首页</span></a></li>
								<li><a>{{type}}</a></li>
								<li><a v-for="(item,index) in hrxzzlist" :key="index"
										v-if="index==num">{{item.article_type_sub}}</a></li>
							</ul>
						</div>
					</div>
					<ul class="news-list">
						<li v-for="(item,index) in list" @click="gotoUrl(item.article_id)"
							v-if="item.isImgShow=='false'">
							<div>
								<div class="title">
									<a>{{item.article_title}}</a>
								</div>
								<div class="introduct text-gray">{{item.short_desc}}</div>
								<div class="date text-grayDark">{{item.add_time}}</div>
							</div>
						</li>
						<el-empty :image-size="200" v-if="list.length==0"></el-empty>
					</ul>
					<div class="product-list images-list imgae-same-size margin-top-20">
						<ul class="marginAuto-15" style="display: flex;">
							<li class="margin-bottom-20 item text-center">
								<a v-for="(item,index) in list" @click="gotoUrl(item.article_id)"
									v-if="item.isImgShow=='ture'" style="float: left;width:50%">
									<div class="boxs">
										<div class="imgHover"><img :src="item.article_img" data-bindresize="1"
												style="width: 400px; height: 150px;"></div>
										<div class="title">
											{{item.article_title}}
										</div>
									</div>
								</a>
							</li>
						</ul>
					</div>
					<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page="currentPage" :page-sizes="[5, 10]" :page-size="pagesize"
						layout="total, sizes, prev, pager, next" :total="total" v-if="list.length!=0">
					</el-pagination>

				</div>
			</div>
		</div>
		<FOOTER></FOOTER>
	</main>
</template>
<script setup>
	import HEADER from '@/components/HEADER.vue';
	import FOOTER from '@/components/FOOTER.vue';
</script>
<script>
	export default {
		data() {
			return {
				hrxzzlist: '',
				list: '',
				index: 0,
				num: 0,
				type: '',
				navIndex: 0,
				total: 1,
				currentPage: 1, //初始页
				pagesize: 5, //    每页的数据
			}
		},
		methods: {
			back() {
				this.$router.push('/index').catch(err => {
					console.log()
				})
			},
			handleSizeChange(size) {
				var that = this;
				that.pagesize = size;
				that.getList(that.num)
			},
			handleCurrentChange(currentPage) {
				var that = this;
				that.currentPage = currentPage;
				that.getList(that.num)
			},
			//跳转详情
			gotoUrl(article_id) {
				let routeData = this.$router.resolve({
					path: '/details',
					query: {
						article_id: article_id
					}
				});
				window.open(routeData.href, '_blank');
			},
			getList(index) {
				var that = this;
				//页数还原
				if (that.num != index) {
					that.currentPage = 1
					that.pagesize = 5
				}
				that.num = index
				$.ajax({
					url: that.$httpUrls + 'xwzx.php',
					type: "get",
					dataType: "json",
					data: {
						act: 'xwzxlist',
						page: that.currentPage,
						size: that.pagesize
					},
					timeout: 60000,
					contentType: "application/x-www-form-urlencoded; charset=UTF-8",
					success: function(res) {
						that.type = res.type
						that.hrxzzlist = res.hrxzzlist
						that.list = res.hrxzzlist[index].list
						that.total = res.hrxzzlist[index].totle
					}
				})
			},
		},
		mounted() {
			var that = this;
			if (that.$route.query.index) {
				var index = that.$route.query.index;
			} else {
				var index = 0
			}
			that.getList(index)
			
		}
	}
</script>
<style>
	a {
		cursor: pointer;
	}

	.pagebanner {
		overflow: hidden
	}

	.pagebanner img {
		display: block;
		width: 100%;
		transition: .5s;
		-moz-transition: .5s;
		-webkit-transition: .5s;
		-o-transition: .5s;
	}

	.pagebanner img:hover {
		transform: scale(1.1);
	}

	@media(max-width:1000px) {
		.pagebanner img {
			min-height: 220px;
		}
	}

	@media(max-width:1000px) {
		.pagebanner img {
			min-height: 110px;
		}
	}

	.pclogo {
		background: url(../assets/img/header.png) no-repeat;
		text-align: center;
	}

	.color1 {
		color: #808080;
	}

	.color2 {
		color: #0a5096;
	}
</style>