<template>
	<div id="app">
		<router-view />
	</div>
</template>
<script>
	export default {
		name: 'App',
		metaInfo: {
			// if no subcomponents specify a metaInfo.title, this title will be used
			title: '华容县职业中专',
			meta: [{
					charset: 'utf-8'
				},
				{
					name: 'viewport',
					content: 'width=device-width, initial-scale=1'
				}, {
					name: 'description',
					content: '华容县职业中专创办于1985年，为华容县唯一公办中等职业学校，现为国家中等职业教育改革发展示范校、国家级重点职业中专、湖南省卓越中等职业学校、湖南省楚怡“双优”学校。学校坐落在美丽的黄湖山麓、状元湖畔，校园面积230亩，建筑面积8万多平方米。现有在校学生近3800人，教职工220人。开设有机电技术应用、电子电器应用与维修、汽车运用与维修、计算机应用、服装设计与工艺、幼儿保育等12个专业。'
				},
				{
					name: 'keywords',
					content: '华容县职业中专;华容县职业中专官网;华容县中专职业学校官网;'
				}
			]
		}
	}
</script>
<style>
	@import './assets/css/base.css';
	@import './assets/css/style.css';
	@import './assets/css/animate.min.css';
	@import './../node_modules/font-awesome/css/font-awesome.min.css';

	[v-cloak] {
		display: none;
	}
</style>