<template>
	<main v-cloak>
		<HEADER :navIndex="navIndex"></HEADER>
		<div class="pagebanner position-relative">
			<img src="../assets/img/banner0101.jpg" />
		</div>
		<div class="container">
			<div class="row">
				<div class="col-md-3 col-sm-4 col-xs-12">
					<div class="sub-nav-box">
						<div class="sub-nav-title clearfix">
							{{type}}
						</div>
						<div class="sub-nav" id="subNav">
							<ul>
								<li v-for="(item,index) in hrxzzlist" :key="index" @click="getList(index)">
									<a :class="index==num?'color2':'color1'">{{item.article_type_sub}}</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="col-md-9 col-sm-8 col-xs-12">
					<div class="all-Breadcrumb">
						<div class="float-left visible-md hidden-sm hidden-xs" v-for="(item,index) in hrxzzlist"
							:key="index" v-if="index==num">{{item.article_type_sub}}</div>
						<div class="float-right">
							<ul class="breadcrumb">
								<li><a><i class="fa fa-home"></i><span>
											首页</span></a></li>
								<li><a>{{type}}</a></li>
								<li><a v-for="(item,index) in hrxzzlist" :key="index"
										v-if="index==num">{{item.article_type_sub}}</a></li>
							</ul>
						</div>
					</div>
					<ul class="news-list">
						<li v-for="(item,index) in list" @click="gotoUrl(item.article_id)">
							<div class="title">
								<a>{{item.article_title}}</a>
							</div>
							<div class="introduct text-gray">{{item.short_desc}}</div>
							<div class="date text-grayDark">{{item.add_time}}</div>
						</li>
					</ul>
					<el-pagination layout="prev, pager, next" :total="10">
					</el-pagination>
				</div>
			</div>
		</div>
		<FOOTER></FOOTER>
	</main>
</template>
<script setup>
	import HEADER from '@/components/HEADER.vue';
	import FOOTER from '@/components/FOOTER.vue';
</script>
<script>
	export default {
		data() {
			return {
				hrxzzlist: '',
				list: '',
				index: 0,
				num: 0,
				type: '',
				url: 'xxgk.php',
				act: 'xxgklist',
				navIndex: 0,
			}
		},
		watch: {
			$route() {
				this.$router.go(0)
				this.getList(0); //换成你的方法
			}
		},
		methods: {
			//跳转详情
			gotoUrl(article_id) {
				this.$router.push("/details?article_id=" + article_id);
			},
			getList(index) {
				var that = this;
				that.num = index
				$.ajax({
					url: that.$httpUrls + that.url,
					type: "get",
					dataType: "json",
					data: {
						act: that.act,
					},
					timeout: 60000,
					contentType: "application/x-www-form-urlencoded; charset=UTF-8",
					success: function(res) {
						that.type = res.type
						that.hrxzzlist = res.hrxzzlist
						that.list = res.hrxzzlist[index].list
					}
				})
			},
		},
		mounted() {
			var that = this;
			that.url = that.$route.query.php;
			if (that.url == 'xxgk.php') {
				that.navIndex = 2
				that.act = 'xxgklist'
			} else if (that.url == 'djjj.php') {
				that.navIndex = 3
				that.act = 'djjjlist'
			} else if (that.url == 'dyyd.php') {
				that.navIndex = 4
				that.act = 'dyydlist'
			} else if (that.url == 'jxyd.php') {
				that.navIndex = 5
				that.act = 'jxydlist'
			} else if (that.url == 'zsjy.php') {
				that.navIndex = 6
				that.act = 'zsjylist'
			} else if (that.url == 'jysx.php') {
				that.navIndex = 7
				that.act = 'jysxlist'
			} else if (that.url == 'fzgh.php') {
				that.navIndex = 8
				that.act = 'fzghlist'
			} else if (that.url == 'shfw.php') {
				that.navIndex = 9
				that.act = 'shfwlist'
			} else if (that.url == 'xwzx.php') {
				that.navIndex = 1
				that.act = 'xwzxlist'
			}
			that.getList(0)
		}
	}
</script>
<style>
	a {
		cursor: pointer;
	}

	.pagebanner {
		overflow: hidden
	}

	.pagebanner img {
		display: block;
		width: 100%;
		transition: .5s;
		-moz-transition: .5s;
		-webkit-transition: .5s;
		-o-transition: .5s;
	}

	.pagebanner img:hover {
		transform: scale(1.1);
	}

	@media(max-width:1000px) {
		.pagebanner img {
			min-height: 220px;
		}
	}

	@media(max-width:1000px) {
		.pagebanner img {
			min-height: 110px;
		}
	}

	.pclogo {
		background: url(../assets/img/header.png) no-repeat;
		text-align: center;
	}

	.color1 {
		color: #808080;
	}

	.color2 {
		color: #0a5096;
	}
</style>