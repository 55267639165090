<template>
	<main v-cloak>
		<HEADER></HEADER>
		<div class="pagebanner position-relative">
			<img src="../assets/img/banner0101.jpg" />
		</div>
		<div class="container">
			<div class="row">
				<div class="col-md-3 col-sm-4 col-xs-12">
					<div class="sub-nav-box">
						<div class="sub-nav-title clearfix">
							校长信箱
						</div>
					</div>
				</div>
				<div class="col-xs-12" style="margin-top: 20px;">
					<el-form :model="ruleForm" label-width="100px" :rules="rules" ref="ruleForm">
						<el-form-item label="联系电话" style="width: 290px;" prop="phone">
							<el-input v-model="ruleForm.phone"></el-input>
						</el-form-item>
						<el-form-item label="信息内容" prop="desc">
							<el-input type="textarea" rows="10" v-model="ruleForm.desc"></el-input>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="submitForm('ruleForm')">提交</el-button>
							<el-button @click="resetForm('ruleForm')">重置</el-button>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</div>
		<FOOTER></FOOTER>
	</main>
</template>
<script setup>
	import HEADER from '@/components/HEADER.vue';
	import FOOTER from '@/components/FOOTER.vue';
</script>
<script>
	export default {
		data() {
			return {
				ruleForm: {
					phone: '',
					desc: '',
				},
				rules: {
					phone: [{
							required: true,
							message: '请输入联系电话',
							trigger: 'blur'
						},
						{
							pattern: /^1[3-9]\d{9}$/,
							message: '手机号格式错误',
							trigger: 'blur'
						},
					],
					desc: [{
						required: true,
						message: '请输入信息内容',
						trigger: 'blur'
					}, ],
				}
			}
		},
		methods: {
			submitForm(formName) {
				var that = this
				that.$refs[formName].validate((valid) => {
					if (valid) {
						that.$confirm('请确认是否提交?', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'info'
						}).then(() => {
							$.ajax({
								url: that.$httpUrls + 'hrxzz.php',
								type: "post",
								dataType: "json",
								data: {
									act: 'xzxx',
									phone: that.ruleForm.phone,
									desc: that.ruleForm.desc,
								},
								timeout: 60000,
								contentType: "application/x-www-form-urlencoded; charset=UTF-8",
								success: function(res) {
									that.$message({
										type: 'success',
										message: res.optMsg
									});
									that.$router.push('/index')
								}
							})
						}).catch(() => {
							that.$message({
								type: 'info',
								message: '已取消'
							});
						});
					} else {
						return false;
					}
				});
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
		},
		mounted() {
			var that = this;

		}
	}
</script>
<style>
	a {
		cursor: pointer;
	}

	.pagebanner {
		overflow: hidden
	}

	.pagebanner img {
		display: block;
		width: 100%;
		transition: .5s;
		-moz-transition: .5s;
		-webkit-transition: .5s;
		-o-transition: .5s;
	}

	.pagebanner img:hover {
		transform: scale(1.1);
	}

	@media(max-width:1000px) {
		.pagebanner img {
			min-height: 220px;
		}
	}

	@media(max-width:1000px) {
		.pagebanner img {
			min-height: 110px;
		}
	}

	.pclogo {
		background: url(../assets/img/header.png) no-repeat;
		text-align: center;
	}

	.color1 {
		color: #808080;
	}

	.color2 {
		color: #0a5096;
	}
</style>