<template>
	<main v-cloak>
		<img src='../assets/img/top.jpg' style='position: absolute;top:-8000px' />
		<HEADER navIndex="0"></HEADER>
		<div class="pagebanner position-relative">
			<img src="../assets/img/banner0101.jpg" />
		</div>
		<section>
			<div class="index-College-bg">
				<div class="maxWidthIndex">
					<div class="index-College-box marginAuto-15">
						<div class="col-md-4 col-sm-4 col-xs-12  wow slideInUp animated" data-wow-delay="0.1s"
							style="visibility: visible; animation-delay: 0.1s; animation-name: slideInUp;">
							<div class="index-College-title">
								<p class="all-index-title">学校要闻</p>
								<div class="float-right" @click="goto('/information')"><a
										class="all-index-more">更多&gt;&gt;</a>
								</div>
							</div>
							<div class="index-College-list" v-for="(item,index) in xwzxlist.xxywlist" :key="index">
								<div class="date">
									<p>{{item.time[2]}}</p>
									<div>{{item.time[0]}}-{{item.time[1]}}</div>
								</div>
								<div class="text">
									<p @click="gotoUrl(item.article_id)"><a>{{item.article_title}}</a></p>
									<div class="card2 visible-md hidden-sm hidden-xs">{{item.short_desc}}</div>
								</div>
							</div>
						</div>
						<div class="col-md-4 col-sm-4 col-xs-12  wow slideInUp animated" data-wow-delay="0.2s"
							style="visibility: visible; animation-delay: 0.2s; animation-name: slideInUp;">
							<div class="index-College-title">
								<p class="all-index-title">通知公告</p>
								<div class="float-right" @click="goto('/information?index=1')"><a
										class="all-index-more">更多&gt;&gt;</a></div>
							</div>
							<div class="index-College-list" v-for="(item,index) in xwzxlist.tzgglist" :key="index">
								<div class="date">
									<p>{{item.time[2]}}</p>
									<div>{{item.time[0]}}-{{item.time[1]}}</div>
								</div>
								<div class="text">
									<p @click="gotoUrl(item.article_id)"><a>{{item.article_title}}</a>
									</p>
									<div class="card2 visible-md hidden-sm hidden-xs">{{item.short_desc}}</div>
								</div>
							</div>
						</div>
						<div class="col-md-4 col-sm-4 col-xs-12 wow slideInUp animated" data-wow-delay="0.3s"
							style="visibility: visible; animation-delay: 0.3s; animation-name: slideInUp;">
							<div id="index-College-slideBox" class="index-College-slideBox">
								<div class="bd">
									<el-carousel :height="bannerHeight+'px'">
										<el-carousel-item v-for="(item,index) in xwzxlist.xxywlist" :key="index">
											<img ref="bannerHeight" :src="item.article_img" @load="imgLoad"
												@click="gotoUrl(item.article_id)">
										</el-carousel-item>
									</el-carousel>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!--校园新闻-->
			<div class="index-news wow slideInUp animated" style="visibility: visible; animation-name: slideInUp;">
				<div class="maxWidthIndex">
					<div class="index-news-Title">
						<p class="all-index-title">学校概况</p>
						<div class="float-right" @click="goto('/schoolprofile')"><a
								class="all-index-more">更多&gt;&gt;</a>
						</div>
					</div>
					<div class="index-news-box marginAuto-15">
						<div class="col-md-6 col-col-sm-6 col-xs-12">
							<ul class="index-news-leftList marginAuto-15">
								<li v-for="(item,index) in xxgklist.xxjjlist" :key="index"
									@click="gotoUrl(item.article_id)" v-if="index<2">
									<div class="imgBox"><a><img :src="item.article_img"></a></div>
									<div class="textBox">
										<p class="card1"><a>{{item.article_title}}</a>
										</p>
										<div class="card2">{{item.short_desc}}</div>
									</div>
								</li>
							</ul>
						</div>
						<div class="col-md-6 col-col-sm-6 col-xs-12">
							<ul class="index-news-rightList">
								<li @click="gotoUrl(item.article_id)" v-for="(item,index) in xxgklist.xxjjlist"
									:key="item.article_id" v-if="index=='0'">
									<div class="text" style="margin-left:0px;">
										<p class="card1"><a>华容县职业中专</a></p>
										<div class="card2">
											{{item.short_desc}}
										</div>
									</div>
								</li>
								<li v-for="(item,index) in fzghlist.fzghlist" :key="index"
									@click="gotoUrl(item.article_id)"><a class="card1">{{item.short_desc}}</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<!--学校动态 、 专题专栏-->
			<div class="index-College-bg">
				<div class="maxWidthIndex">
					<div class="index-College-box marginAuto-15">
						<div class="col-md-41 col-sm-41 col-xs-12 wow slideInUp animated" data-wow-delay="0.1s"
							style="visibility: visible; animation-delay: 0.1s; animation-name: slideInUp;">
							<div class="index-College-title">
								<p class="all-index-title">党建纪检</p>
								<div class="float-right" @click="goto('/partybuilding')"><a
										class="all-index-more">更多&gt;&gt;</a></div>
							</div>
							<div class="index-College-list index-College-list1"
								v-for="(item,index) in djjjlist.djjjlist" :key="index"
								@click="gotoUrl(item.article_id)">
								<img :src="item.article_img"
									style="width: 200px;float: left;margin-right: 10px;height: 112px;" class="listImg"
									v-if="index==0" />
								<div class="text text1" v-if="index==0">
									<p><a style="color: #0b74c3;">{{item.article_title}}</a></p>
									<div class="card2 visible-md hidden-sm hidden-xs"
										style="height: 88px;margin-top: 15px;">{{item.short_desc}}</div>
								</div>
								<div class="text" v-if="index!=0">
									<p><a>{{item.article_title}}</a></p>
								</div>
							</div>
						</div>
						<div class="col-md-41 col-sm-41 col-xs-12 wow slideInUp animated" data-wow-delay="0.1s"
							style="visibility: visible; animation-delay: 0.1s; animation-name: slideInUp;">
							<div class="index-College-title">
								<p class="all-index-title">德育园地</p>
								<div class="float-right" @click="goto('/moraleducation')"><a
										class="all-index-more">更多&gt;&gt;</a></div>
							</div>
							<div class="index-College-list index-College-list1"
								v-for="(item,index) in dyydlist.dyydlist" :key="index"
								@click="gotoUrl(item.article_id)">
								<img :src="item.article_img"
									style="width: 200px;float: left;margin-right: 10px;height: 112px;" class="listImg"
									v-if="index==0" />
								<div class="text text1" v-if="index==0">
									<p><a style="color: #0b74c3;">{{item.article_title}}</a></p>
									<div class="card2 visible-md hidden-sm hidden-xs"
										style="height: 88px;margin-top: 15px;">{{item.short_desc}}</div>
								</div>
								<div class="text" v-if="index!=0">
									<p><a>{{item.article_title}}</a></p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!--校园数据-->
			<div class="maxWidthIndex wow slideInLeft animated"
				style="visibility: visible; animation-name: slideInLeft;">
				<div class="CampusData marginAuto-15">
					<div class="CampusData-title">
						<p class="all-index-title">校园数据</p>
					</div>
					<div class="col-md-3 col-sm-3 col-xs-6 text-center">
						<div class="number-box">
							<p>{{xysj.cby}}</p>
							<i class="fa fa-building"></i>
							<div class="card2">{{xysj.cby_m}}</div>
						</div>
					</div>
					<div class="col-md-3 col-sm-3 col-xs-6 text-center">
						<div class="number-box">
							<p>{{xysj.wyx}}</p>
							<i class="fa fa-bank"></i>
							<div class="card2">{{xysj.wyx_m}}</div>
						</div>
					</div>
					<div class="col-md-3 col-sm-3 col-xs-6 text-center">
						<div class="number-box">
							<p>{{xysj.mj}}</p>
							<i class="fa fa-thumbs-up"></i>
							<div class="card2">{{xysj.mj_m}}</div>
						</div>
					</div>
					<div class="col-md-3 col-sm-3 col-xs-6 text-center">
						<div class="number-box">
							<p>{{xysj.jzmj}}</p>
							<i class="fa fa-users"></i>
							<div class="card2">{{xysj.jzmj_m}}</div>
						</div>
					</div>
					<div class="col-md-3 col-sm-3 col-xs-6 text-center">
						<div class="number-box">
							<p>{{xysj.zxxs}}</p>
							<i class="fa fa-graduation-cap"></i>
							<div class="card2">{{xysj.zxxs_m}}</div>
						</div>
					</div>
					<div class="col-md-3 col-sm-3 col-xs-6 text-center">
						<div class="number-box">
							<p>{{xysj.jzg}}</p>
							<i class="fa fa-globe"></i>
							<div class="card2">{{xysj.jzg_m}}</div>
						</div>
					</div>
					<div class="col-md-3 col-sm-3 col-xs-6 text-center">
						<div class="number-box">
							<p>{{xysj.jyl}}</p>
							<i class="fa fa-briefcase"></i>
							<div class="card2">{{xysj.jyl_m}}</div>
						</div>
					</div>
					<div class="col-md-3 col-sm-3 col-xs-6 text-center">
						<div class="number-box">
							<p>{{xysj.syl}}</p>
							<i class="fa fa-money"></i>
							<div class="card2">{{xysj.syl_m}}</div>
						</div>
					</div>
				</div>
			</div>
			<!--图片-->
			<div class="Index-images wow slideInRight animated"
				style="visibility: visible; animation-name: slideInRight;">
				<div class=" col-sm-6 col-xs-12 padding-0" v-for="(item,index) in xyfjlist.xyfjlist" :key="index">
					<div class="Index-images-one" @click="gotoUrl(item.article_id)">
						<a :title="item.article_title">
							<img :src="item.article_img">
							<div class="mask"></div>
							<div class="text">{{item.article_title}}</div>
						</a>
					</div>
				</div>
			</div>


			<!--教研实训 、 社会服务-->
			<div class="index-College-bg" style="margin-top:40px;">
				<div class="maxWidthIndex">
					<div class="index-College-box marginAuto-15">
						<div class="col-md-41 col-sm-41 col-xs-12 wow slideInUp animated" data-wow-delay="0.1s"
							style="visibility: visible; animation-delay: 0.1s; animation-name: slideInUp;">
							<div class="index-College-title">
								<p class="all-index-title">教研实训</p>
								<div class="float-right" @click="goto('/practicaltraining')"><a
										class="all-index-more">更多&gt;&gt;</a></div>
							</div>
							<div class="index-College-list index-College-list1"
								v-for="(item,index) in jysxlist.jysxlist" :key="index"
								@click="gotoUrl(item.article_id)">
								<img :src="item.article_img"
									style="width: 200px;float: left;margin-right: 10px;height: 112px;" class="listImg"
									v-if="index==0" />
								<div class="text text1" v-if="index==0">
									<p><a style="color: #0b74c3;">{{item.article_title}}</a></p>
									<div class="card2 visible-md hidden-sm hidden-xs"
										style="height: 88px;margin-top: 15px;">{{item.short_desc}}</div>
								</div>
								<div class="text" v-if="index!=0">
									<p><a>{{item.article_title}}</a></p>
								</div>
							</div>
						</div>
						<div class="col-md-41 col-sm-41 col-xs-12 wow slideInUp animated" data-wow-delay="0.1s"
							style="visibility: visible; animation-delay: 0.1s; animation-name: slideInUp;">
							<div class="index-College-title">
								<p class="all-index-title">社会服务</p>
								<div class="float-right" @click="goto('/socialservices')"><a
										class="all-index-more">更多&gt;&gt;</a></div>
							</div>
							<div class="index-College-list index-College-list1"
								v-for="(item,index) in shfwlist.shfwlist" :key="index"
								@click="gotoUrl(item.article_id)">
								<img :src="item.article_img"
									style="width: 200px;float: left;margin-right: 10px;height: 112px;" class="listImg"
									v-if="index==0" />
								<div class="text text1" v-if="index==0">
									<p><a style="color: #0b74c3;">{{item.article_title}}</a></p>
									<div class="card2 visible-md hidden-sm hidden-xs"
										style="height: 88px;margin-top: 15px;">{{item.short_desc}}</div>
								</div>
								<div class="text" v-if="index!=0">
									<p><a>{{item.article_title}}</a></p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!--专题专栏-->
			<div class="index-Special-bg">
				<div class="maxWidthIndex">
					<div class="index-Special-title">
						<p class="all-index-title">招生就业</p>
						<div class="float-right" @click="goto('/recruitment')"><a class="all-index-more">更多&gt;&gt;</a>
						</div>
					</div>
					<div class="index-Special-box marginAuto-15">
						<div class="col-md-3 col-sm-6 col-xs-12 wow slideInUp animated" data-wow-delay="0.1s"
							style="visibility: visible; animation-delay: 0.1s; animation-name: slideInUp;"
							v-for="(item,index) in zszclist.zszclist" :key="index">
							<div class="index-Special-imgBox"><a :title="item.article_title"><img
										:src="item.article_img"></a></div>
						</div>
					</div>
				</div>
			</div>
			<!--快捷链接-->
			<div class="index-link-bg wow slideInUp animated" data-wow-delay="0.5s"
				style="visibility: visible; animation-delay: 0.5s; animation-name: slideInUp;">
				<div class="maxWidthIndex">
					<div class="index-link-title">
						<p class="all-index-title">专业介绍</p>
						<div class="float-right" @click="goto('/recruitment?index=3')"><a
								class="all-index-more">更多&gt;&gt;</a>
						</div>
					</div>
					<ul class="index-link-box">
						<li v-for="(item,index) in zyjslist.zyjslist" :key="index"><a><img class="fa faimg"
									:src="item.article_img" @click="gotoUrl(item.article_id)"
									:title="item.short_desc" />
								<p>{{item.article_title}}</p>
							</a></li>
					</ul>
				</div>
			</div>
		</section>
		<FOOTER></FOOTER>
	</main>
</template>
<script src="../assets/js/basic.js"></script>
<script src="../assets/js/wow.min.js"></script>
<script setup>
	import HEADER from '@/components/HEADER.vue';
	import FOOTER from '@/components/FOOTER.vue';
</script>
<script>
	export default {
		data() {
			return {
				imgList: ['banner0101.jpg'],
				bannerHeight: '',
				xwzxlist: '', //通知公告
				xxgklist: '', //学校概况
				djjjlist: '', //党建纪检
				dyydlist: '', //德育园地
				jysxlist: '', //教研实训
				shfwlist: '', //社会服务
				fzghlist: '', //发展规划
				zyjslist: '', //专业介绍
				zszclist: '', //招生政策
				xyfjlist: '', //校园风景
				xysj: '', //校园数据
			}
		},
		methods: {
			//校园数据
			getXysj() {
				var that = this;
				$.ajax({
					url: that.$httpUrls + 'hrxzz.php',
					type: "get",
					dataType: "json",
					data: {
						act: "xysj",
					},
					timeout: 60000,
					contentType: "application/x-www-form-urlencoded; charset=UTF-8",
					success: function(res) {
						that.xysj = res
					}
				})
			},
			//校园风景
			getXyfj() {
				var that = this;
				$.ajax({
					url: that.$httpUrls + 'xxgk.php',
					type: "get",
					dataType: "json",
					data: {
						act: "xyfjlist_m",
					},
					timeout: 60000,
					contentType: "application/x-www-form-urlencoded; charset=UTF-8",
					success: function(res) {
						that.xyfjlist = res
					}
				})
			},
			//招生政策
			getZszc() {
				var that = this;
				$.ajax({
					url: that.$httpUrls + 'zsjy.php',
					type: "get",
					dataType: "json",
					data: {
						act: "zszclist_m",
					},
					timeout: 60000,
					contentType: "application/x-www-form-urlencoded; charset=UTF-8",
					success: function(res) {
						that.zszclist = res
					}
				})
			},
			//专业介绍
			getZyjs() {
				var that = this;
				$.ajax({
					url: that.$httpUrls + 'zsjy.php',
					type: "get",
					dataType: "json",
					data: {
						act: "zyjslist_m",
					},
					timeout: 60000,
					contentType: "application/x-www-form-urlencoded; charset=UTF-8",
					success: function(res) {
						that.zyjslist = res
					}
				})
			},
			//发展规划
			getFzgh() {
				var that = this;
				$.ajax({
					url: that.$httpUrls + 'fzgh.php',
					type: "get",
					dataType: "json",
					data: {
						act: "fzghlist_m",
					},
					timeout: 60000,
					contentType: "application/x-www-form-urlencoded; charset=UTF-8",
					success: function(res) {
						that.fzghlist = res
					}
				})
			},
			//社会服务
			getShfw() {
				var that = this;
				$.ajax({
					url: that.$httpUrls + 'shfw.php',
					type: "get",
					dataType: "json",
					data: {
						act: "shfwlist_m",
					},
					timeout: 60000,
					contentType: "application/x-www-form-urlencoded; charset=UTF-8",
					success: function(res) {
						that.shfwlist = res
					}
				})
			},
			//教研实训
			getJysx() {
				var that = this;
				$.ajax({
					url: that.$httpUrls + 'jysx.php',
					type: "get",
					dataType: "json",
					data: {
						act: "jysxlist_m",
					},
					timeout: 60000,
					contentType: "application/x-www-form-urlencoded; charset=UTF-8",
					success: function(res) {
						that.jysxlist = res
					}
				})
			},
			//德育园地
			getDyyd() {
				var that = this;
				$.ajax({
					url: that.$httpUrls + 'dyyd.php',
					type: "get",
					dataType: "json",
					data: {
						act: "dyydlist_m",
					},
					timeout: 60000,
					contentType: "application/x-www-form-urlencoded; charset=UTF-8",
					success: function(res) {
						that.dyydlist = res
					}
				})
			},
			//党建纪检
			getDjjj() {
				var that = this;
				$.ajax({
					url: that.$httpUrls + 'djjj.php',
					type: "get",
					dataType: "json",
					data: {
						act: "djjjlist_m",
					},
					timeout: 60000,
					contentType: "application/x-www-form-urlencoded; charset=UTF-8",
					success: function(res) {
						that.djjjlist = res
					}
				})
			},
			//新闻资讯
			getXwzx() {
				var that = this;
				$.ajax({
					url: that.$httpUrls + 'xwzx.php',
					type: "get",
					dataType: "json",
					data: {
						act: "xwzxlist_m",
					},
					timeout: 60000,
					contentType: "application/x-www-form-urlencoded; charset=UTF-8",
					success: function(res) {
						that.xwzxlist = res
						window.addEventListener('resize', () => {
							that.imgLoad()
						}, false)
					}
				})

			},
			//学校概况
			getXxgk() {
				var that = this;
				$.ajax({
					url: that.$httpUrls + 'xxgk.php',
					type: "get",
					dataType: "json",
					data: {
						act: "xxgklist_m",
					},
					timeout: 60000,
					contentType: "application/x-www-form-urlencoded; charset=UTF-8",
					success: function(res) {
						that.xxgklist = res
					}
				})
			},
			//跳转详情
			gotoUrl(article_id) {
				this.$router.push("/details?article_id=" + article_id);
			},
			goto(url) {
				this.$router.push(url);
			},
			//轮播图跟随页面大小
			imgLoad() {
				this.$nextTick(() => {
					this.bannerHeight = this.$refs.bannerHeight[0].height
				})
			},
		},
		mounted() {
			this.getXysj()
			this.getXyfj()
			this.getZszc()
			this.getZyjs()
			this.getFzgh()
			this.getShfw()
			this.getJysx()
			this.getDyyd()
			this.getDjjj()
			this.getXwzx()
			this.getXxgk()
			// this.imgLoad()
			$("title").text('华容县职业中专');

		}
	}
</script>
<style>
	.faimg {
		width: 80px;
		margin: 10px 0;
	}

	.index-College-list1 .text {
		margin-left: 0;
	}
</style>