<template>
	<main v-cloak>
		<footer>
			<div class="container addfoot">
				<div class="line-height-2 text-white font-size-16">
					版权所有 © 2024 华容县职业中专
					<p>招生热线：<img src="../assets/lxr.png"
							style="width: 16px;height: 16px;margin-bottom: 2px;margin-right: 3px;" />胡老师
						13974063340 <img src="../assets/lxr.png"
							style="width: 16px;height: 16px;margin-bottom: 2px;margin-right: 3px;" />张老师
						13762078505</p>
					<p>联系地址：湖南省华容县章华镇黎淳东路03号</p>
					<p>ICP备案：<a href="https://beian.miit.gov.cn/" target="_blank"
							style="color: #fff;">湘ICP备2024044396号</a></p>
				</div>
			</div>
		</footer>
		<!--置顶-->
		<!--首页置顶-->
		<div class="menu-icon">
			<ul>
				<li class="goToTop gzh">
					<img src="../assets/gzh.jpg" class="gzhImg" />
					<a title="公众号" target="_blank">
						<i class="fa fa-wechat" aria-hidden="true"></i>公众号
					</a>
				</li>
				<li class="goToTop">
					<a title="校长信箱" target="_blank" @click="goUrl()">
						<i class="fa fa-envelope" aria-hidden="true"></i>校长信箱
					</a>
				</li>
				<li class="goToTop" @click="goTop">
					<a title="返回顶部" target="_blank">
						<i class="fa fa-arrow-up" aria-hidden="true"></i>返回顶部
					</a>
				</li>
			</ul>
		</div>
		<!--手机端固定-->
		<div class="bg-bottgray position-fixed bott-fixed phoblack">
			<img src="../assets/gzh.jpg" class="gzhImg1" id="gzhImg1" />
			<div class="kaoright" @click="dianjiImg">
				<a title="公众号" target="_blank">
					<i class="fa fa-wechat" aria-hidden="true"></i>
				</a>
			</div>
			<ul class="text-center backsame">
				<li class="col-xs-4" @click="back()">
					<a>
						<i class="fa fa-home"></i>
						<span>首页</span>
					</a>
				</li>
				<li class="col-xs-4">
					<a @click="goUrl()">
						<i class="fa fa-envelope"></i>
						<span class="text-white font-size-12">校长信箱</span>
					</a>
				</li>
				<li class="col-xs-4">
					<div class="curpointer goToTop" @click="goTop">
						<a>
							<i class="fa fa-caret-square-o-up"></i>
							<span>置顶</span>
						</a>
					</div>
				</li>
			</ul>
		</div>
	</main>
</template>
<script>
	export default {
		name: 'FOOTER',
		data() {
			return {
				oror: true
			}
		},
		methods: {
			dianjiImg() {
				if (this.oror) {
					document.getElementById('gzhImg1').style = 'transform: translateX(-100%)'
				} else {
					document.getElementById('gzhImg1').style = 'transform: translateX(100%)'
				}
				this.oror = !this.oror
			},
			//校长信箱
			goUrl() {
				this.$router.push('/mailbox')
			},
			back() {
				this.$router.push('/index').catch(err => {
					console.log()
				})
			},
			goTop() {
				//给一个定时器，有过度效果
				var time = setInterval(function() {
					let top = pageYOffset;
					let step = Math.ceil(top / 50);
					window.scroll(0, top - step); //进行平滑
					//如果到达顶部，清除定时器
					if (top == 0) {
						clearInterval(time);
					}
				})
			}
		},
		mounted() {}
	}
</script>

<style scoped>
	a {
		cursor: pointer;
	}

	.gzhImg {
		position: absolute;
		right: -110px;
		bottom: 50px;
		width: 100%;
		transition: transform 0.5s ease-in-out;
	}

	.gzh:hover .gzhImg {
		transform: translateX(-88%);
	}

	.kaoright {
		position: absolute;
		right: 0px;
		padding: 10px;
		bottom: 80px;
		background: rgba(76, 133, 192, 0.8);
	}

	.kaoright a {
		color: #fff;
	}

	.gzhImg1 {
		width: 100px;
		position: absolute;
		bottom: 120px;
		right: -100px;
		transition: transform 0.5s ease-in-out;
	}

	.pclogo {
		background: url(../assets/img/header.png) no-repeat;
		text-align: center;
	}

	ul>li>ul,
	ul>li>ol,
	ol>li>ol,
	ol>li>ul {
		margin: 0em 0;
	}
</style>