<template>
	<main v-cloak>
		<div class="header">
			<div class="pclogo">
				<div class="maxWidthIndex">
					<div class="maxWidthIndexRow">
						<!-- <div class="hrTitle" style="cursor: pointer;">华容县职业中专</div> -->
						<a class="logo">
							<img src="../assets/titlebig.png" style="width:310px;">
						</a>
					</div>
				</div>
			</div>
			<div class="pcNav">
				<div class="maxWidthIndex">
					<div class="nav" id="navMenu">
						<ul id="DIVUL">
							<li :class="navIndex==index?'active current':''" v-for="(item,index) in titleList">
								<a @click="goto(item.url)">{{item.article_type}}</a>
								<ul>
									<li class="animated navSlide" v-for="(item1,index1) in item.list">
										<a @click="goto(item1.url)">{{item1.article_type_sub}}</a>
									</li>
								</ul><span class="arrow" @click="dianji(index)"></span>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<div class="mask-bg" id="maskBg" @click="xiaoshi"></div>
			<span class="nav-toggle hidden-lg" id="navToggle" @click="zhankai"><i
					class="fa fa-bars text-white"></i></span>
		</div>
	</main>
</template>
<script>
	export default {
		name: 'HEADER',
		props: ['navIndex'],
		data() {
			return {
				titleList: []
			}
		},
		methods: {
			goto(url) {
				localStorage.setItem("url", url);
				this.$router.push('/loading');
			},
			//打开手机端菜单
			zhankai() {
				document.getElementById('maskBg').classList.add('active')
				document.getElementById('navMenu').classList.add('active')
			},
			//取消遮罩层
			xiaoshi() {
				document.getElementById('maskBg').classList.remove('active')
				document.getElementById('navMenu').classList.remove('active')
			},
			dianji(index) {
				if (document.getElementById('DIVUL').children[index].classList.value) {
					document.getElementById('DIVUL').children[index].classList.remove('active')
				} else {
					document.getElementById('DIVUL').children[index].classList.add('active')
				}
			},
			getTitle() {
				var that = this;
				$.ajax({
					url: that.$httpUrls + 'hrxzz.php',
					type: "get",
					dataType: "json",
					data: {
						act: "title",
					},
					timeout: 60000,
					contentType: "application/x-www-form-urlencoded; charset=UTF-8",
					success: function(res) {
						that.titleList = res.titlelist
					}
				})
			},
		},
		mounted() {
			this.getTitle()
			if (this.titleList.length != 0) {

			}
		}

	}
</script>
<style scoped>
	a {
		cursor: pointer;
	}

	.slideBoxBanner {
		width: 100%;
		overflow: hidden;
		position: relative;
	}

	.slideBoxBanner .hd {
		width: 100%;
		position: absolute;
		z-index: 1;
		bottom: 10px;
		left: 0;
		height: 30px;
		line-height: 30px;
		text-align: center;
	}

	.slideBoxBanner .hd ul li {
		background: #d6d6d6;
		cursor: pointer;
		display: inline-block;
		*display: inline;
		zoom: 1;
		width: 10px;
		height: 10px;
		margin: 2px;
		overflow: hidden;
		filter: alpha(opacity=40);
		opacity: 0.9;
		border-radius: 15px;
	}

	.slideBoxBanner .hd ul li.on {
		background: #165aa1;
		color: #fff;
	}

	.slideBoxBanner .bd {
		position: relative;
		height: 100%;
		z-index: 0;
	}

	.slideBoxBanner .bd li {
		zoom: 1;
		vertical-align: middle;
	}

	.slideBoxBanner .bd img {
		width: 100%;
		display: block;
	}

	.slideBoxBanner .prev,
	.slideBoxBanner .next {
		position: absolute;
		left: 3%;
		top: 50%;
		margin-top: -25px;
		display: block;
		width: 40px;
		height: 40px;
		filter: alpha(opacity=50);
		background: rgba(0, 0, 0, .5);
		text-align: center;
		line-height: 33px;
	}

	.slideBoxBanner .prev:hover,
	.slideBoxBanner .next:hover {
		background-color: #0b74c3;
	}

	.slideBoxBanner .next {
		left: auto;
		right: 3%;
		background-position: 8px 5px;
	}

	.slideBoxBanner .prev:hover,
	.slideBoxBanner .next:hover {
		filter: alpha(opacity=100);
		opacity: 1;
	}

	.slideBoxBanner .prevStop {
		display: none;
	}

	.slideBoxBanner .nextStop {
		display: none;
	}

	.pclogo {
		background: url(../assets/img/banner021.jpg) no-repeat;
		text-align: center;
	}


	ul>li>ul,
	ul>li>ol,
	ol>li>ol,
	ol>li>ul {
		margin: 0em 0;
	}
</style>