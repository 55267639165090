<template>
	<main v-cloak>
		<HEADER></HEADER>
		<div class="pagebanner position-relative">
			<img src="../assets/img/banner0101.jpg" />
		</div>
		<div class="container">
			<div class="all-page-bg">
				<div class="detail-breadCrumb">
					<ul class="breadcrumb">
						<li @click="back()"><a><i class="fa fa-home"></i><span>首页</span></a></li>
						<li @click="goto(listInfo.article_type_url)"><a>{{listInfo.article_type}}</a></li>
						<li @click="goto(listInfo.article_type_sub_url)"><a>{{listInfo.article_type_sub}}</a></li>
					</ul>
				</div>
				<p class="text-center font-size-24 line-height-1-5 padding-bottom-10 padding-top-30">
					{{listInfo.article_title}}
				</p>
				<div class="text-center">
					<span title="发布时间" class="margin-right-20"
						v-if="listInfo.add_time">发布时间：{{listInfo.add_time}}</span>
					<span title="点击数" v-if="listInfo.clicknum">点击数量：<span
							id="lb_clicks">{{listInfo.clicknum}}</span></span>
				</div>
				<div class="info-content padding-top-20 case-news" id="Content" v-html="listInfo.art_content">
				</div>

				<!-- <div class="clearfix padding-top-20 padding-bottom-20 line-height-2 case-news">
					<ul>
						<li>下一篇：<a>校领导检查指导民族学院2022届毕业生就业工作</a></li>
					</ul>
				</div> -->
			</div>
		</div>
		<FOOTER></FOOTER>
	</main>
</template>
<script setup>
	import HEADER from '@/components/HEADER.vue';
	import FOOTER from '@/components/FOOTER.vue';
</script>
<script>
	export default {
		data() {
			return {
				listInfo: {},
			}
		},
		methods: {
			back() {
				this.$router.push('/index').catch(err => {
					console.log()
				})
			},
			goto(url) {
				localStorage.setItem("url", url);
				this.$router.push('/loading');
			},
		},
		mounted() {
			var that = this;
			that.article_id = that.$route.query.article_id;
			$.ajax({
				url: that.$httpUrls + 'hrxzz.php',
				type: "POST",
				dataType: "json",
				data: {
					act: "hrxzzinfo",
					article_id: that.article_id
				},
				timeout: 60000,
				contentType: "application/x-www-form-urlencoded; charset=UTF-8",
				success: function(res) {
					that.listInfo = res
				}
			})
		}
	}
</script>
<style>
	img {
		width: 100%;
	}
</style>